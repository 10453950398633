import { useRouter } from "next/router";
import SidebarData from "../../data/dashboard/student/siderbar.json";
import Link from "next/link";
import { useAuthContext } from "@/context/auth/AuthContext";
import { useAppContext } from "@/context/Context";

const openModal = async (id) => {
  const bootstrap = await import("bootstrap");
  const modalElement = document.getElementById(id);

  if (modalElement) {
    let modalInstance = bootstrap.Modal.getInstance(modalElement);

    if (!modalInstance) {
      modalInstance = new bootstrap.Modal(modalElement);
    }

    modalInstance.show();
  }
};
const StudentDashboardSidebar = () => {
  const router = useRouter();
  const path = router.pathname;
  const { user } = useAuthContext();
  const { setMobile } = useAppContext();
  return (
    <div className="inner">
      <div className="content-item-content">
        <div className="rbt-default-sidebar-wrapper">
          <div className="section-title mb--20">
            <h6 className="rbt-title-style-2">
              Welcome, {`${user?.firstName} ${user?.lastName}`}
            </h6>
          </div>
          <nav className="mainmenu-nav">
            <ul className="dashboard-mainmenu rbt-default-sidebar-list">
              {SidebarData &&
                SidebarData.siderbar.map((data, index) => (
                  <li className="nav-item" key={index} role="presentation">
                    {data.locked ? (
                      <div
                        className="avatar"
                        data-tooltip="Section is Locked"
                        tabIndex="0"
                      >
                        <div
                          className={`${path === data.link ? "active" : ""}`}
                        >
                          <i className={data.icon} />
                          <span>{data.text}</span>
                          <i className="feather-lock ms-3" />
                        </div>
                      </div>
                    ) : (
                      <Link
                        onClick={() => {
                          setMobile(true);
                        }}
                        className={`${path === data.link ? "active" : ""}`}
                        href={data.link || "#"}
                      >
                        <i className={data.icon} />
                        <span>{data.text}</span>
                      </Link>
                    )}
                  </li>
                ))}
            </ul>
          </nav>
        </div>
      </div>
      <div
        className="modal fade"
        id="locked"
        tabIndex="-1"
        aria-labelledby="lockedLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body">
              <div className="modal-overlay">
                <div className="modal-header">
                  <h4 className="text-danger">
                    Locked
                    <i className="ms-2 feather-alert-triangle"></i>
                  </h4>
                </div>
                <div className="modal-body">
                  <p>
                    Reloading the page can cause significant time delays and
                    lead to the loss of your current progress, including
                    incomplete or unsaved answers. This action will also
                    negatively impact the overall performance and experience.
                  </p>
                </div>
              </div>
            </div>
            <div className="top-circle-shape"></div>
            <div className="modal-footer pt--30">
              <button
                type="button"
                className="rbt-btn btn-border btn-sm radius-round-10"
                data-bs-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StudentDashboardSidebar;
